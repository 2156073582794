/* eslint-disable class-methods-use-this */

import BaseCollection from './_Base'
import Category from '../models/Category'
import { fetchCategoryProductNameRequest } from '@/request/globalApi/requests/categoryRequests'

/**
 * CategoriesCollection
 * @linkhttps://vuemc.io/#collection-instances
 */
class CategoriesCollection extends BaseCollection {
  defaults() {
    return {
      items: {},
    }
  }

  options() {
    return {
      model: Category,
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  /**
   * GET /category/:id
   * @returns
   */
  async fetchProductName(id, queryParams) {
    // Sort categories by categoryId to prevent multi same request
    if (this.get('items')[`cat${id}`]) return
    const { data } = await fetchCategoryProductNameRequest(id, queryParams)
    this.set({ items: { ...this.get('items'), [`cat${id}`]: data.items } })
  }
}

export default CategoriesCollection
