<template>
  <b-tabs>
    <b-tab
      v-for="(lang, index) in locales"
      :key="index"
      :active="isActive(lang.locale)"
      @click="$emit('switch-lang', lang.locale, index), destroyDefaultLanguage"
    >
      <template #title>
        <b-img :src="lang.img" class="mr-50" height="14px" width="22px" />
        <span>{{ lang.locale }} </span>
        <span style="color: #dc3545">
          {{ defaultLocale === lang.locale ? ' *' : '' }}
        </span>
      </template>
      <slot />
    </b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: 'InputLang',

  props: {
    placeHolder: {
      type: String,
      default: '',
    },
    defaultLocale: {
      type: String,
      default: localStorage.getItem('appLang'),
    },
    activeLanguage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      locales: this.$store.state.auth.activelang,
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  methods: {
    isActive(locale) {
      if (this.activeLanguage === locale) {
        return true
      }
      return this.activeLanguage === '' && this.userLang === locale
    },
    destroyDefaultLanguage() {
      this.defaultLanguage = ''
    },
  },
}
</script>

<style>
.m-0 .nav.nav-tabs {
  margin-bottom: 0;
}
</style>
